import React from "react";

import Ready from "../ready/ready";
import WorkDone from "../get_started/work_done";
import TopBanner from "../top_banner/product_top_banner";
import FAQS from "../faqs/faqs";
import Value from "../value/value_scalability";
import Help from "../help/help";
import SecondaryHeader from "../secondary-header";
import { secondaryMenu } from "../../constants";
import { useTranslation } from 'react-i18next';

const Acces = () => {
  const {t} = useTranslation();
  const values = [
    {
      title: `${t('navigator')}`,
      description: `${t('the_mereos_platform_requires')}`,
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/world-search.svg"
    },
    {
      title: `${t('equipment')}`,
      description: `${t('the_computer_used_to_administer')}`,
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/headphone-orange-round.svg"
    },
    {
      title: `${t('internet_speed')}`,
      description: `${t("the_minimum_speed")}`,
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/thumbs-up-orange.svg"
    }
  ];

  const analysisData = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/monitor-orange-round.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/candidate-identity-card.svg",
      title: `${t('receivability_of_an_identity_document')}`,
      description: `${t('the_id_presented_will_be')}`,
      button: {
        text: `${t('consult_the_rules')}`,
        href: ""
      }
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/bulb-orange-round.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/math-solving.svg",
      title: `${t('ban_on_personal_effects')}`,
      description: `${t('with_the_exception_of_identity')}`,
      button: {
        text: `${t('learn_more')}`,
        href: ""
      }
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/acedmic-cap-orange-round.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/masking-before-monitor.svg",
      title: `${t('invalidity_of_the_tasem')}`,
      description: `${t('mereos_administrator_of_the_test')}`,
      button: {
        text: `${t('consult_the_rules')}`,
        href: ""
      }
    },
  ];

  const supportFAQS = [
    {
      title: "Why don't you count usage/ active users/ tests taken as opposed to licensed users?",
      description: "Your success is our success. We want you to sell as much as humanly possible- if you win first, we can also win. As such, we need our incentives to be aligned. We want you to build the best possible product. We firmly believe that the more active your users are… the more value they will derive from the product… the more likely they are to stick around, and to tell their friends. If we make it about usage, you are explicitly disincentivized  to build a great product that people use.",
    },
    {
      title: "Can I buy just one product or feature / Do I have to buy the whole product suite?",
      description: `${t('no_in_accordance_with_cnil')}`,
    },
    {
      title: "Can I build a Proof-of-Concept before I buy?",
      description: `${t('no_mereos_does_not_monitor_candidates')}`,
    },
    {
      title: "Is Learnosity for me?",
      description: `${t('subtle_movements_such_as_those_mentioned')}`,
    },
    {
      title: "What’s your renewal process?",
      description: `${t('no_the_mereos_algorithm_only_provides')}`,
    },
    {
      title: "What is a user?",
      description: `${t('no_the_mereos_algorithm_only_provides')}`,
    },
  ];

  return (
    <div className={`font-sans`}>
      <SecondaryHeader
        menu={secondaryMenu}
      />
      <TopBanner
        pageTitle={t('procedures_and_regulations')}
        title={t('provisions_relating')}
        description={t('rules_and_recommendations')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/calandar-events.svg"
        page={"platform"}
        pageType={"left"}
      />

      <div className={`mx-auto w-11/12 lg:w-5/6 my-20`}>
        <div className="my-20 mb-28">
          <Value
            title={t('technical_requirements')}
            values={values}
            leftStar
            rightStar
          />
        </div>
        <WorkDone
          title={t('procedures_and_regulations')}
          data={analysisData}
          page={'tasem-test'}
          titleFull
        />
        <div className="md:mb-28 mb-28 mt-20">
          <Help
            title = {t('access_the_test_regulations')}
            description= {t('it_aims_to_define_all_the_procedural')}
            descriptionStyle={{
              textAlign: 'justify'
            }}
            button= {{
              text: `${t('test_rules')}`,
              href: ""
            }}
          />
        </div>
        <FAQS
          title={t('questions_applicable_rules_and_procedures')}
          faqsType='Support FAQs'
          data={supportFAQS}
        />
        <div className="mx-auto my-20">
          <Ready
            title={t('ready_to_test_mereos')}
            description={t('discover_our_ready_to_use_solutions')}
            leftButton={{
              text: `${t('register')}`,
              href: "https://registration.mereos.eu/candidature/?id=1"
            }}
            rightButton={{
              text: t('learn_more'),
              href: ""
            }}
            image="https://d2lxkizvrhu4im.cloudfront.net/images/Market_launch.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Acces;
