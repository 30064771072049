import * as React from "react";

import Acces from "../../../components/tasem-test/acces";
import Layout from "../../../components/layout/layout";
import Seo from "../../../components/seo";

const Index = () => {
  return (
    <>
      <Seo
        title="Dispositions relatives au TASEM "
        description="Les règles et recommandations destinées aux candidats souhaitant passer le test en ligne TASEM,
        notamment la configuration technique requise, les effets personnels autorisés ou l’invalidité des
        sessions de test TASEM."
      />
      <Layout>
        <Acces />  
      </Layout>
    </>
  )
}

export default Index;
